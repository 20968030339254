
import { Options, Vue } from "vue-class-component";

const screenWidth =
  window.screen.availWidth >= window.innerWidth
    ? window.screen.availWidth
    : window.innerWidth;
const screenHeight =
  window.screen.availHeight >= window.innerHeight
    ? window.innerHeight
    : window.screen.availHeight;
const unit = screenWidth / 1000;

@Options({
  props: {
    msg: {
      type: String,
      default: "유통기한 : 2025년까지",
    },
  },
})
export default class Home extends Vue {
  msg!: string;

  mounted() {
    this.style();
    window.addEventListener("resize", this.style);
  }

  unmounted() {
    window.removeEventListener("resize", this.style);
  }

  public handleImageClick() {
    const home = document.getElementById("home");
    if (home) {
      console.log("너무 개빡친다 " + scrollY);
    }
  }

  private style() {
    const home = document.getElementById("home");
    const wrapper = document.getElementById("wrapper");
    if (home && wrapper) {
      home.style.width = 100 + "%";
      home.style.height = 100 + "%";

      const background = this.$el.querySelector(".background") as HTMLElement;
      background.style.width = `${screenWidth}px`;
      const fish = this.$el.querySelector(".fish") as HTMLElement;
      fish.style.width = `${350 * unit}px`;
      fish.style.top = `${50 * unit}px`;
      const scale = wrapper.offsetWidth / background.offsetWidth;
      if (scale > 1) {
        background.style.width = `${screenWidth * scale}px`;
        fish.style.width = `${350 * unit * scale}px`;
        fish.style.top = `${50 * unit * scale}px`;
      }
    }

    const title = this.$el.querySelector(".title") as HTMLElement;
    title.style.top = `${150 * unit}px`;
    title.style.left = `${20 * unit}px`;
    title.style.width = `${500 * unit}px`;
    title.style.height = `${300 * unit}px`;

    const title_h1 = this.$el.querySelector(".title h1") as HTMLElement;
    title_h1.style.top = `${15 * unit}px`;
    title_h1.style.fontSize = `${20 * unit}px`;

    const title_h3 = this.$el.querySelector(".title h3") as HTMLElement;
    title_h3.style.fontSize = `${11 * unit}px`;

    const title_p = this.$el.querySelector(".title p") as HTMLElement;
    title_p.style.bottom = `${0 * unit}px`;
    title_p.style.fontSize = `${10 * unit}px`;
  }
}
